import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/banner'

const WorksWith = () => {
  return (
    <Layout className="works-with">
      <SEO title="Works With" />
      <Banner
        title="We can provision and de-provision customers onto all of these services, and we’re literally always adding more"
        description="To check out how each integration works and make sure it’s right for your business, we’d recommend that you sign up for a free trial - you can try everything completely commitment free for a month and see how many hours GetOnboard.ly can save your business."
      />
      <div className="body-wrapper"></div>
    </Layout>
  )
}

export default WorksWith
