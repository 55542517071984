import React from 'react'
import clsx from 'clsx';

import Splode from '../images/splode-green.svg'
import BillsbyLogo from '../images/billsby-logo.svg'

const Banner = ({ title, description, homeBanner, pricingBanner }) => {
  return (
    <div  className={clsx("banner",
    { "pricing-banner": pricingBanner }
  )}>
      <div className="container">
        <img className="splode" src={Splode} alt="splode" />
        <div className="content-wrapper">
          {pricingBanner && (
            <div className="white-div"><p>Special Launch Pricing</p></div>
          )}
          <h1 className="heading-1">{title}</h1>
          {description && (
            <p>{description}</p>
          )}
          {homeBanner && (
            <div className="logo-wrapper">
              <p>from</p>
              <img className="billsby-logo" src={BillsbyLogo} alt="splode" />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Banner
